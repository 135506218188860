
<template>
  <nav
    id="nav"
    class="navbar navbar-static-top white-bg"
    role="navigation"
    style="margin-bottom: 0"
  >
    <div class="navbar-header">
      <a
        class="navbar-minimalize minimalize-styl-2 btn btn-primary"
        href="#"
        @click="smoothlyMenu"
      >
        <span v-if="isHide" class="showIcon"
          ><i class="fa fa-bars shou"></i
        ></span>
        <!-- <i v-else class="fa fa-angle-double-left" ></i> -->
        <svg
          style="
            font-size: 20px;
            vertical-align: middle;
            width: 24px;
            height: 24px;
            padding: 0 5px 5px 5px;
          "
          v-else
          class="icon"
        >
          <use xlink:href="#icon-menua-lianhe21"></use>
        </svg>
      </a>

      <form
        role="search"
        class="navbar-form-custom"
        action="search_results.html"
      >
        <div class="form-group">
          <!--<input type="text" placeholder="Search for something..." class="form-control" name="top-search" id="top-search">-->
        </div>
      </form>
    </div>
    <ul class="nav navbar-top-links navbar-right">
      <li class="nav-item dropdown" v-if="showRegion">
        <a
          class="nav-link dropdown-toggle"
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          @click="getNavRegion"
        >
          {{ currentRegion.name || "-" }}
          <span class="caret"></span>
        </a>

        <div class="dropdown-menu">
          <a
            class="dropdown-item"
            v-for="(region, n) in regionList"
            v-on:click="changeRegion(region)"
            :key="`${region.id}_${n}`"
          >
            {{ region.name }}
          </a>
        </div>
      </li>
      <li style="padding: 20px">
        <span class="m-r-sm text-muted welcome-message"
          >{{ userInfo.name }}，欢迎访问智科运营系统</span
        >
      </li>
      <li>
        <a v-on:click="logout()"> <i class="fa fa-sign-out"></i> 退出 </a>
      </li>
    </ul>
  </nav>
</template>
<script>
import { queryRegionListUrl } from "@/requestUrl";
import { STORAGE_KEY, JOB_LEVEL, DEPARTMENT_TYPES } from "@/constant";

export default {
  name: "Nav",
  data() {
    return {
      isHide: false,
      DEPARTMENT_TYPES,
      JOB_LEVEL,
      regionList: [],
      nav: {
        moreNoticeUrl: "/flow/noticeFlow",
        notices: [],
        total: 0,
      },
      logo: "",
      userInfo: this.$vc.getCurrentStaffInfo(),
      currentRegion: {},
    };
  },
  computed: {
    showRegion() {
      const userInfo = this.$vc.getCurrentStaffInfo();
      return (
        userInfo.jobLevel === JOB_LEVEL.SUPERVISOR &&
        [DEPARTMENT_TYPES.PRESIDENT_OFFICE].includes(
          +userInfo.dutyType
        ) &&
        this.$route.name !== "appletsData"
      );
    },
  },
  async created() {
    const regionList = await this.getNavRegion(),
      regionInfo = this.$vc.getCurrentRegion();
    this.currentRegion = regionInfo;
    console.log(this.currentRegion);
    // 如果该员工、用户有所属园区，登陆后会默认选中
    if (!regionInfo) {
      regionList.forEach((region) => {
        if (region.code === this.userInfo.regionCode) {
          this.changeRegion(region, false);
        }
      });
    }
    // this.$fly.post('/meter/meter/getById?id=2')
  },
  methods: {
    smoothlyMenu() {
      event.preventDefault();
      $("body").toggleClass("mini-navbar");
      if (
        !$("body").hasClass("mini-navbar") ||
        $("body").hasClass("body-small")
      ) {
        // Hide menu in order to smoothly turn on when maximize menu
        $("#side-menu").hide();
        // For smoothly turn on menu
        this.$vc.setMenuState("ON");
        setTimeout(function () {
          $("#side-menu").fadeIn(400);
        }, 200);
        this.isHide = false;
        this.$vc.emit("chengMenuStatus", true);
      } else if ($("body").hasClass("fixed-sidebar")) {
        $("#side-menu").hide();
        this.$vc.setMenuState("ON");
        this.$vc.emit("chengMenuStatus", true);
        setTimeout(function () {
          $("#side-menu").fadeIn(400);
        }, 100);
        this.isHide = false;
      } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $("#side-menu").removeAttr("style");
        this.$vc.emit("chengMenuStatus", false);
        this.$vc.setMenuState("OFF");
        this.isHide = true;
      }
    },
    logout: function () {
      this.$vc.clearCacheData();
      this.$router.push({ name: "defaultPage" });
    },
    async getNavRegion() {
      // const regionList = JSON.parse(
      //     sessionStorage.getItem(STORAGE_KEY.REGION_LIST)
      // );
      // if (regionList && regionList != "") {
      //     this.regionList = regionList;
      //     return regionList;
      // }
      return this.$fly
        .post(queryRegionListUrl, {
          code: "",
          name: "",
        })
        .then((res) => {
          const regionList = res.data.datas || [];
          this.regionList = regionList;
          sessionStorage.setItem(
            STORAGE_KEY.REGION_LIST,
            JSON.stringify(regionList)
          );
          return regionList;
        });
    },
    /**
     * 选择园区
     * @param {Object} region 园区信息
     * @param {Boolean} isRefreshPage 是否刷新页面
     *  */
    changeRegion(region, isRefreshPage = true) {
      this.currentRegion = region;
      localStorage.setItem(STORAGE_KEY.CURRENT_REGION, JSON.stringify(region));

      if (isRefreshPage) {
        this.$router.go(0);
      }
    },
  },
};
</script>


<style lang="stylus" scoped>
#nav {
  margin-left: 0;
  margin-right: 0;
  /* position fixed
  top 20px
  right 0 */
}

.icon {
  font-size: 20px;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-left: -7px;
  margin-top: -1px;
  position: absolute;
  left: 33px;
  top: 22px;
}

.nav-item.dropdown {
  a {
    &:hover {
      color: #000;
    }
  }
}
</style>
